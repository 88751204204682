// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contributi-js": () => import("./../../../src/pages/contributi.js" /* webpackChunkName: "component---src-pages-contributi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-wines-js": () => import("./../../../src/pages/wines.js" /* webpackChunkName: "component---src-pages-wines-js" */),
  "component---src-templates-custom-menu-js": () => import("./../../../src/templates/custom_menu.js" /* webpackChunkName: "component---src-templates-custom-menu-js" */),
  "component---src-templates-wines-wine-section-js": () => import("./../../../src/templates/wines/wine_section.js" /* webpackChunkName: "component---src-templates-wines-wine-section-js" */)
}

