module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vecchio Falconiere","short_name":"vecchio falconiere","start_url":"/","background_color":"#e46f4d","theme_color":"#e46f4d","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ff57e2f711789b233116288f187f82f8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-159211170-1","head":false,"anonymize":true,"respectDNT":true,"cookieDomain":"vecchiofalconiere.it","exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics-gdpr/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-159211170-1","enableDevelopment":false,"anonymizeIP":true,"autoStartWithCookiesEnabled":false,"reactGaOptions":{"debug":false,"gaOptions":{"sampleRate":10}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
